exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-events-the-namm-show-js": () => import("./../../../src/pages/events/the-namm-show.js" /* webpackChunkName: "component---src-pages-events-the-namm-show-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/Brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/Series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-user-js": () => import("./../../../src/templates/User.js" /* webpackChunkName: "component---src-templates-user-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/Video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "slice---src-components-footer-footer-js": () => import("./../../../src/components/footer/Footer.js" /* webpackChunkName: "slice---src-components-footer-footer-js" */),
  "slice---src-components-header-header-js": () => import("./../../../src/components/header/Header.js" /* webpackChunkName: "slice---src-components-header-header-js" */)
}

